import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './AppItem.scss';
import { App, AppType } from '../../store/apps/state';
import IosButton from '../../Components/DownloadButton/IosButton';
import AndroidButton from '../../Components/DownloadButton/AndroidButton';
import { getLink } from '../../store/apps/selectors';


interface Props {
  //link: string;
  app: App
  colorStyle: string;
  index?: number;
}

const AppItem = (props: Props) => {

  const { app, colorStyle, index, } = props;
  //const iconSize = DefaultIconSize;

  const indexClass = index === 0 ? 'first' : '';

  const iosLink = getLink(app, 'ios');
  const androidLink = getLink(app, 'android');

  if(app.type === AppType.Web) {
    return (
      <div className={'app-item web-item '+colorStyle+' '+indexClass}>
      <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="content">
                <h1>{app.name}</h1>
                <h2>{app.info}</h2>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="website-img-wrap">
                <img className="website" src={app.webImage} />
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    )
  }

  return (
    <div className={'app-item '+colorStyle+' '+indexClass}>
      <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="content">
                <div className="app-icon-wrap">
                  <img className="app-icon-rounded" src={app.appIcon} alt=""/>
                </div>
                <h1>{app.name}</h1>
                <h2>{app.info}</h2>
                <div className="btn-wrap">
                  <Row>
                    {iosLink &&
                      <Col sm={12} md={12} lg={12}>
                        <IosButton link={iosLink} />
                      </Col>
                    }
                    {androidLink &&
                      <Col sm={12} md={12} lg={12}>
                        <AndroidButton link={androidLink} />
                      </Col>
                    }
                  </Row>
                  {/* <DownloadButton link={'/'} type={DownloadButtonType.LearnMore} /> */}
                </div>
                {/* <div style={{marginTop: 40}}>
                  <LearnMore link={app.landingPage} />
                </div> */}
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="phone-wrap">
                <img className="phone" src={app.phoneImage} />
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default AppItem;