import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { FaFacebook, FaGithub, FaYoutube, } from 'react-icons/fa';
import { IoMdAppstore, IoIosAppstore } from 'react-icons/io';
import Environment from '../../Environment';
import { getSocialMenu, SocialItem } from './social';
import './SocialBar.scss';
import { MenuArea } from 'Components/AppHeader/nav';
import _ from 'lodash';

interface Props {
  iconSize: number;
  storeIconSize: number;
  iconColor: string;
  area: MenuArea,
}

const SocialBar = ( { area, iconSize, storeIconSize, iconColor } : Props) => {

  const items = getSocialMenu(area, iconSize, iconColor);

  const components = _.map(items, (x: SocialItem) => {
    return (
      <a key={x.link} href={x.link} target="_blank">
        {x.icon}
      </a>
    )
  })

  //const components = ( 

  return (
    <div className="social-bar">
      {components}
    </div>
  )
};

export default SocialBar;