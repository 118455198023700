import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form'
import { Container, Row, Col } from 'react-grid-system';
import { FiSend } from 'react-icons/fi';
import './SupportForm.scss';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import validator from 'validator';
import { ClipLoader } from "react-spinners";
import { saveContact } from '../../Api/contact';

interface Props {
  selectedReason: string;
}

const SupportForm = ( { selectedReason } : Props) => {

  //const { link, } = props;
  //const iconSize = DefaultIconSize;

  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  const { register, handleSubmit, watch, errors, setValue, getValues, reset, } = useForm()
  
  setValue('reason', selectedReason, true);

  const onSubmit = async (data: any) => { 

    setShowFailed(false);
    setDisabled(true);
    //console.log(data);

    const success = await saveContact(data);
    if(success) {
      setShowSuccess(true);
      setTimeout(() => {
        reset();
        setDisabled(false);
        setShowSuccess(false);
      }, 4000);
    }

    else {
      setShowFailed(true);
      setDisabled(false);
    }
    
  }

  return (
    
    <div className={'support-form'}>

      <div className="success-box" hidden={!showSuccess}>
        <FiSend size={100} />
        <p><b>{getValues().name}</b>, thank you for contacting us!<br /> We will get back to you as soon as possible.</p>
      </div>

      <form hidden={showSuccess} onSubmit={handleSubmit(onSubmit)}>
        
        <input type="hidden" name="reason" ref={register({
            validate: value => {
              return !validator.isEmpty(value);
            }
          })} />

          {errors.reason && 
            <ErrorMessage message={'Please select a reason above'} />
          }


        <div className="input-box">
          <label htmlFor="name">Name</label>
          <input disabled={disabled} type="text" id="name" name="name" className={''+(errors.name ? 'error' : '')} defaultValue="" ref={register({
            validate: value => {
              const len = validator.isLength(value, {
                min: 2,
              });

              const empty = validator.isEmpty(value, {
                ignore_whitespace: true,
              })

              return len && !empty;
            }
          })} />
          {errors.name && 
            <ErrorMessage message={'Please enter your name'} />
          }
        </div>

        <div className="input-box">
          <label htmlFor="email">Email</label>
          <input disabled={disabled} type="text" id="email" className={''+(errors.email ? 'error' : '')} name="email" defaultValue="" ref={register({
            validate: value => {
              return validator.isEmail(value);
            }
          })} />
          {errors.email && 
            <ErrorMessage message={'Please enter a valid email address'} />
          }
        </div>
        
        <div className="input-box">
          <label htmlFor="message">Message</label>
          <textarea disabled={disabled} name="message" id="message" className={''+(errors.message ? 'error' : '')} ref={register({
            validate: value => {
              return !validator.isEmpty(value, {
                ignore_whitespace: true
              })
            }
          })}></textarea>
          {errors.message && 
            <ErrorMessage message={'Please provide a message'} />
          }
        </div>

      
      {/* include validation with required or other standard HTML validation rules */}
      {/* <input type="text" name="exampleRequired" ref={register({ required: true })} /> */}
      {/* errors will return when field validation fails  */}
      {/* {errors.exampleRequired && <span>This field is required</span>} */}
        <div className="submit-wrap">

          {showFailed &&
            <div>
              <ErrorMessage message={'There was an error submitting the form. Please try again.'} />
              <br />
            </div>
          }

          <button disabled={disabled} className={''+(disabled ? 'disabled' : '')} type="submit">
            <span>Send</span> 
            {!disabled && 
              <div className="send-icon-wrap">
                <FiSend />
              </div>
            }
            
            <ClipLoader
              size={32} // or 150px
              color={"#000"}
              loading={disabled}
            />
          </button>
        </div>
      </form>
    </div>
    
  )
}

export default SupportForm;