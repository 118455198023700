import axios from 'axios';

export const SUPPORT_SEND_REQUEST = 'support.send-request';
export const SUPPORT_REQUEST_ERROR = 'support.request-error';
export const SUPPORT_REQUEST_SUCCESS = 'support.request-success';
export const SUPPORT_CLEAR = 'support.clear';

export const supportRequestError = (error: any) => (
    {
        type: SUPPORT_REQUEST_ERROR,
        error,
    }
);

export const supportRequestSuccess = () => (
    {
        type: SUPPORT_REQUEST_SUCCESS,
    }
);

export const supportClear = () => (
    {
        type: SUPPORT_CLEAR,
    }
);

export const sendRequest = (data: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: SUPPORT_SEND_REQUEST
        });
        try {
            const response = await axios.post('http://localhost:5001/api/support', data);
            if(response.status !== 200) {
                dispatch(supportRequestError('Something went wrong. Please try again later.'));
                return;
            }
            else {
                dispatch(supportRequestSuccess());
            }
        }
        catch(e) {
            dispatch(supportRequestError('Something went wrong. Please try again later.'));
        }
    }
};