import axios from 'axios';
import Environment from '../Environment';

const defaultContact = {
	reason: 'Other',
	source: 'cometloop.com',
};

export const saveContact = async (contact: any): Promise<boolean> => {
	const url = `${Environment.SupportApiBaseUrl}/support`;
	try {
		const response = await axios.post<any>(url, {
			...defaultContact,
			...contact,
		});
		return response.status === 200;
	} catch (e) {
		console.warn(e);
		return false;
	}
};
