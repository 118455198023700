import React, { useState } from 'react';
import AppHeader from '../../Components/AppHeader/AppHeader';

// import { Container, Row, Col } from 'react-grid-system';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Waypoint } from 'react-waypoint';
import ContactForm from '../../Components/ContactForm/ContactForm';

import { FaTablet } from 'react-icons/fa';
import {
	MdPhoneIphone,
	MdDevices,
	MdPhoneAndroid,
	MdDesktopMac,
} from 'react-icons/md';
import { IoIosPhonePortrait, IoIosLaptop } from 'react-icons/io';
import { GoDeviceDesktop } from 'react-icons/go';

import IosButton from '../../Components/DownloadButton/IosButton';
import AndroidButton from '../../Components/DownloadButton/AndroidButton';
import LearnMore from '../../Components/LearnMore/LearnMore';
import NewBadge from '../../Components/NewBadge/NewBadge';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { getApp, getLink } from '../../store/apps/selectors';
import { App } from '../../store/apps/state';
import PortfolioBlock from '../../Components/PortfolioBlock/PortfolioBlock';
import './Home.scss';
import ConsultationButton from '../../Components/ConsultationButton/ConsultationButton';
import ServiceHighlight from '../Services/ServiceHighlight';
import { homeServiceItems } from '../Services/items';

import _ from 'lodash';
import AppItem from '../../Components/AppItem/AppItem';

interface Props {
	featuredApp: App;
}

export const HomePage = (props: Props) => {
	const { featuredApp } = props;
	const [blueBoxInViewport, setBlueBoxInViewport] = useState(false);

	const onEnterViewport = () => {
		setBlueBoxInViewport(true);
	};

	const onExitViewport = () => {};

	const blueBoxClass = blueBoxInViewport ? 'animated pulse' : '';

	const iosLink = getLink(featuredApp as App, 'ios') as string;
	const androidLink = getLink(featuredApp as App, 'android') as string;

	const serviceHighlights = _.map(homeServiceItems, (item) => {
		return <ServiceHighlight key={item.title} {...item} />;
	});

	return (
		<div className='home-wrap'>
			<div className='home-top'>
				<Container fluid={true}>
					<Row>
						<Col>
							<div className='nav-padding'></div>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<div className='top'>
								<h1>
									Web &amp; Mobile <br />
									Development Services
								</h1>
								<div className='btn-wrap'>
									<ConsultationButton
										link={'/contact?type=free-consultation'}
									/>
								</div>
							</div>
						</Col>
						<Col md={6}>
							<div>
								<img className='img' src='/devices.png' alt='' />
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			<div>{serviceHighlights}</div>

			{/* <PortfolioBlock /> */}

			{/* <AppItem app={featuredApp} colorStyle='blue' />  */}
		</div>
	);
};

const mapStateToProps = (state: AppState) => {
	return {
		featuredApp: getApp(state.apps, 'sound-machine') as App,
	};
};

export default connect(mapStateToProps)(HomePage);
