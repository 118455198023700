import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './AppHeader.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { FaFacebook, FaAppStoreIos } from 'react-icons/fa';
import { IoMdAppstore, IoIosAppstore, IoIosMenu } from 'react-icons/io';
import Environment from '../../Environment';
import SocialBar from '../SocialBar/SocialBar';
import { connect } from 'react-redux';
import { MenuArea, menu, MenuItem, } from './nav';
import _ from 'lodash';
import { openMobileMenu } from '../../store/menu/actions';

const IconSize = 22;
const StoreIconSize = 26;
const stickyOffset = 200;

const checkIsSticky = (y: number) => {
  return (y >= stickyOffset);
}

interface Props {
  open: () => void;
}

const AppHeaderServer = (props: Props | any) => {

  const pathname = props.location.pathname || null;
  const isSticky = false;
  const stickyClass = isSticky ? 'sticky' : '';
  const logo = isSticky ? '/comet-loop-logo-black.png' : '/logo-white-transparent-200px.png';
  const iconColor = isSticky ? '#000' : '#fff';

  const menuItems = _.map(menu(MenuArea.Desktop), (x: MenuItem) => {
    return (
      <Link key={x.link} className={'page-links '+(pathname === x.link ? 'active' : '')} to={x.link}>
        {x.name}
      </Link>
    );
  });

  return (
    <div className={`header ${stickyClass}`}>
      <div className="left">
        <Link to="/">
          <img src={logo} alt="Comet Loop LLC"/>
        </Link>
      </div>
      <div className="center">
        <nav>
          {menuItems}
        </nav>
      </div>
      <div className="right">
        <div className="social-bar">
          <SocialBar
            area={MenuArea.Desktop} 
            iconSize={IconSize} 
            storeIconSize={StoreIconSize} 
            iconColor={iconColor} />
        </div>
        <div onClick={() => open()} className="menu">
          <IoIosMenu size={35} color={iconColor} />
        </div>
      </div>      
    </div>
  )
}


export const AppHeader = (props: Props | any) => {

  const { open } = props;

  if(typeof window === 'undefined') {
    return ( <AppHeaderServer {...props} /> );
  }

  // get path for current page
  const pathname = props.location.pathname || null;

  const [isSticky, setIsSticky] = useState(checkIsSticky(window.pageYOffset));

  const onScroll = (e: any) => {
    const sticky = checkIsSticky(window.pageYOffset);
    setIsSticky(sticky);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
    window.removeEventListener('scroll', onScroll);
    };
  }, [])

  const stickyClass = isSticky ? 'sticky' : '';
  const logo = isSticky ? '/comet-loop-logo-black.png' : '/logo-white-transparent-200px.png';
  const iconColor = isSticky ? '#000' : '#fff';

  const menuItems = _.map(menu(MenuArea.Desktop), (x: MenuItem) => {
    return (
      <Link key={x.link} className={'page-links '+(pathname === x.link ? 'active' : '')} to={x.link}>
        {x.name}
      </Link>
    );
  });

  return (
    <div className={`header ${stickyClass}`}>
      <div className="left">
        <Link to="/">
          <img src={logo} alt="Comet Loop LLC"/>
        </Link>
      </div>
      <div className="center">
        <nav>
          {menuItems}
        </nav>
      </div>
      <div className="right">
        <div className="social-bar">
          <SocialBar
            area={MenuArea.Desktop} 
            iconSize={IconSize} 
            storeIconSize={StoreIconSize} 
            iconColor={iconColor} />
        </div>
        <div onClick={() => open()} className="menu">
          <IoIosMenu size={35} color={iconColor} />
        </div>
      </div>      
    </div>
  )
}

const mapStateToProps = () => ({
  
});

const mapDispatchToProps = (dispatch: any) => ({
  open: () => {
    dispatch(openMobileMenu(true));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader))