import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Environment from '../../Environment';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { FaInfoCircle } from 'react-icons/fa';
import { IoIosAppstore } from 'react-icons/io';
import { Line } from 'rc-progress';
import Skill from '../../Components/Skill/Skill';

import _ from 'lodash';
import './About.scss';

import { composeSkills } from './skills';

const About = () => {


  const skillComponents = () => {

    const skills = _.orderBy(composeSkills(), x => x.percent, 'desc');
    return _.map(skills, x => {
      return (
        <Skill key={x.label} skill={x} />
      )
    })

  }

  return (
    <div className="about">
      <div className="about-bg"></div>

      <Container>
        <Row>
          <Col>
            <div className="info">
              {/* <FaInfoCircle className="about-icon" color="#fff" /> */}
              {/* <h1>About</h1> */}
              {/* <img className="avatar" src="/headshot-color.jpg" alt="" /> */}
              <div className="blue-box">
                <p>With 10+ years of experience we learned a few things along the way.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="skills-bg">
        <Container>
          <Row>
            <Col>
              <h2>Our Technical Expertise</h2>
              <div className="skills-wrap">
                {skillComponents()}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

export default About;