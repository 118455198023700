import React, { useState } from 'react';
import { TiWarning } from 'react-icons/ti';
import './ErrorMessage.scss';

interface Props {
  message: string;
}

const ErrorMessage = (props: Props) => {
  const { message, } = props;
  return (
    <div className="error-message">
      <TiWarning /> <span>{message}</span>
    </div>
  )
}

export default ErrorMessage;