import React from 'react';
import { FaRegLightbulb, FaBug } from 'react-icons/fa';
import { IoIosAppstore } from 'react-icons/io';
import { MdFeedback } from 'react-icons/md';
import { WiLightning } from 'react-icons/wi';

export interface Skill {
  icon?: any;
  label: string;
  description: string;
  percent: number;
}

export const composeSkills = () : Skill[] => {
  return [
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'iOS',
      description: 'Creating apps for iPhone & iPad',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Android',
      description: 'Creating Android Apps',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'React Native',
      description: 'Cross Platform Apps for iOS and Android',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'React',
      description: 'Building Modern Web Apps',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: '.NET Core',
      description: 'Building Cross Platform Web Apps',
      percent: 90,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Docker',
      description: 'Containerizing apps, deploying with Docker Swarm',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Typescript',
      description: 'Modern & Clean',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Javascript',
      description: 'Modern & Clean',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'HTML5',
      description: 'Modern & Clean',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'CSS3',
      description: 'Modern & Clean',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'SQL',
      description: 'MySQL, Sqlite, MSSQL',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'NoSQL',
      description: 'MongoDB, CouchDb, CosmosDb',
      percent: 90,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'OAuth 2',
      description: 'Keeping it all secure',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Git',
      description: 'Version Control',
      percent: 70,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Php',
      description: 'It\'s been a while',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Angular JS',
      description: 'I work in this every day',
      percent: 100,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Node JS',
      description: 'My go to scripting language',
      percent: 80,
    },
    {
      icon: <WiLightning size="50" color="#000" />,
      label: 'Python',
      description: 'I did this a long time ago',
      percent: 70,
    }
    
  ]
};