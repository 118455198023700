import React, { useEffect, useState } from 'react';
import { MenuArea } from '../AppHeader/nav';
import Environment from '../../Environment';
import { FaFacebook, FaGithub, FaYoutube, } from 'react-icons/fa';
import { IoMdAppstore, IoIosAppstore } from 'react-icons/io';
import _ from 'lodash';

export interface SocialItem {
  icon: any,
  link: string;
  areas: MenuArea[];
}

export const socialItems = (iconSize: number, iconColor: string) => {
  return [
    {
      icon: <FaFacebook size={iconSize} color={iconColor} />,
      link: Environment.Facebook,
      areas: [MenuArea.Desktop, MenuArea.Footer]
    },
    {
      icon: <FaYoutube size={iconSize} color={iconColor} />,
      link: Environment.Youtube,
      areas: [MenuArea.Desktop, MenuArea.Footer]
    },
    {
      icon: <IoIosAppstore size={iconSize} color={iconColor} />,
      link: Environment.AppStoreDeveloperLink,
      areas: [MenuArea.Desktop, MenuArea.Footer]
    },
    {
      icon: <IoMdAppstore size={iconSize} color={iconColor} />,
      link: Environment.PlayStoreDeveloperLink,
      areas: [MenuArea.Desktop, MenuArea.Footer]
    },
    {
      icon: <FaGithub size={iconSize} color={iconColor} />,
      link: Environment.Github,
      areas: [MenuArea.Desktop, MenuArea.Footer]
    }
  ]
};


export const getSocialMenu = (area: MenuArea, iconSize: number, iconColor: string) => _.filter(socialItems(iconSize, iconColor), x => {
  
  const match = _.find(x.areas, a => {
    //console.log(a, area);
    return a === area
  });

  return match;

});