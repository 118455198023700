import React from 'react';
import { Link } from "react-router-dom";
import Environment from '../../Environment';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import _ from 'lodash';

import './Footer.scss';
import SocialBar from '../SocialBar/SocialBar';
import { menu, MenuArea, MenuItem } from '../../Components/AppHeader/nav';

const IconSize = 28;
const StoreIconSize = 32;
const IconColor = '#fff';

const footer = () => {

  const menuItems = _.map(menu(MenuArea.Footer), (x: MenuItem) => {
    if(x.external) {
      return (
        <li key={x.link}>
          <a href={x.link} target="_blank">
            {x.name}
          </a>
        </li>
      )
    }

    return (
      <li key={x.link}>
        <Link to={x.link}>
          {x.name}
        </Link>
      </li>
      
    );
  });

  return (
    <div className="footer-wrap">
      <Container>
        <Row>
          <Col className="logo-wrap" xs={12}>
            <Link to="/">
              <img className="logo-footer" src="/logo-white-transparent-200px.png" alt="Comet Loop LLC" />
            </Link>
          </Col>
          <Col xs={12} className="footer-links-wrap">
            <ul className="footer-menu">
              {menuItems}
            </ul>
          </Col>
          <Col style={{textAlign: 'center'}} xs={12}>
            <p className="copyright">&copy; 2019 Comet Loop LLC.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer-social">
              <SocialBar
                area={MenuArea.Footer} 
                iconSize={IconSize} 
                storeIconSize={StoreIconSize} 
                iconColor={IconColor} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default footer;