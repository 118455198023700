import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ConsultationButton from '../../Components/ConsultationButton/ConsultationButton';

export enum ServiceHighlightImageSide {
  Left = 'left',
  Right = 'right',
}

export interface ServiceHighlightProps {
  imageSide: ServiceHighlightImageSide,
  title: string;
  description: string;
  link: string;
  className: string;
}

const ServiceHighlight = (props: ServiceHighlightProps) => {

  const { imageSide, title, description, link, className, } = props;


  if(imageSide === ServiceHighlightImageSide.Left) {
    return (
      <div>
        {/* Mobile Version */}
        <div className="area mobile d-block d-md-none">
          <div className={`left img ${className}`}></div>
          <h1>{title}</h1>
          <h2>{description}</h2>
          <div className="cta-wrap">
            <ConsultationButton link={link} />
          </div>
        </div>

        <div className="area d-none d-md-block">
          <Container style={{margin: 0, padding: 0}} fluid={true}>
            <Row style={{margin: 0, padding: 0}}>
              <Col style={{margin: 0, padding: 0}} xs={12} md={6}>
                <div className={`left ${className}`}>
                  
                </div>
              </Col>
              <Col style={{margin: 0, padding: 0}} xs={12} sm={12} md={6}>
                <div className={`right ${className}`}>
                  <h1>{title}</h1>
                  <h2>{description}</h2>
                  <div className="cta-wrap">
                    <ConsultationButton link={link} />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
    )
  }
  else {
    return (
      <div>
        {/* Mobile Version */}
        
        <div className="area mobile d-block d-md-none">
          <div className={`right img ${className}`}></div>
          <h1>{title}</h1>
          <h2>{description}</h2>
          <div className="cta-wrap">
            <ConsultationButton link={link} />
          </div>
        </div>

        <div className="area d-none d-md-block">
          <Container style={{margin: 0, padding: 0}} fluid={true}>
            <Row style={{margin: 0, padding: 0}}>
              <Col style={{margin: 0, padding: 0}} xs={12} sm={12} md={6}>
                <div className={`left ${className}`}>
                  <h1>{title}</h1>
                  <h2>{description}</h2>
                  <div className="cta-wrap">
                    <ConsultationButton link={link} />
                  </div>
                </div>
              </Col>
              <Col style={{margin: 0, padding: 0}} xs={12} sm={12} md={6}>
                <div className={`right ${className}`}>
                    
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default ServiceHighlight;