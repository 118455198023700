import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { FaApple, FaAndroid, FaInfoCircle, FaLongArrowAltRight} from 'react-icons/fa';
import './ConsultationButton.scss';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const DefaultIconSize = 50;

interface Props {
  link: string;
}

const fireEvent = () => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Request Free Consultation'
  });
}

const ConsultationButton = (props: Props) => {

  const { link } = props;

  return (
    <Link onClick={fireEvent} className="btn-consultation" to={link}>
      <span>Request Free Consultation</span>
      {/* <FaLongArrowAltRight size={20} color={'#000'} /> */}
      {/* <FaApple size={iconSize} />
      <div className="copy">
        <span className="line-1">Download on the</span><br />
        <span className="line-2">App Store</span>
      </div> */}
    </Link>
  )
}

export default ConsultationButton;