const Environment = {
	Facebook: 'https://www.facebook.com/cometloop',
	Github: 'https://github.com/cometloop',
	AppStoreDeveloperLink:
		'https://apps.apple.com/us/developer/comet-loop-llc/id1492776000',
	PlayStoreDeveloperLink:
		'https://play.google.com/store/apps/developer?id=Comet+Loop+LLC',
	Youtube: 'https://www.youtube.com/channel/UC69WO6WXpwfkp7YjtS0ZZAA/',
	PrivacyPolicy:
		'https://app.termly.io/document/privacy-policy/8eb65c3e-e605-4c9c-954d-49bb1b67e561',
	SupportApiBaseUrl: 'https://support.cometloop.com',
};

console.log('Environment vars', JSON.stringify(Environment, null, 2));

export default Environment;
