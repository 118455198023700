export enum AppType {
    Web,
    MobileApp,
}

export interface App {
    name?: string,
    uid?: string;
    os?: string[];
    info?: string;
    landingPage?: string;
    appIcon?: string;
    phoneImage?: string;
    webImage?: string;
    featuredBackground?: string;
    state?: string;
    features?: Feature[];
    downloadLinks?: DownloadLink[];
    type?: AppType

}

export interface Feature {
    headline: string;
    copy: string;
    imageUrl: string;
}

export interface DownloadLink {
    url: string;
    os: string;
}

const state: App[] = [
    {
        "type": AppType.MobileApp,
        "name": "Sound Machine Sleep Sounds",
        "uid": "sound-machine",
        "os": ["ios"],
        "info": "Sleep Better. Live Better",
        "landingPage": "/sound-machine",
        "appIcon": "/sound-machine/app-icon-1024.jpg",
        "phoneImage": "/sound-machine/sounds.png",
        "featuredBackground": "https://i.ytimg.com/vi/SN3xlcjbvUo/maxresdefault.jpg",
        "state": 'available',
        "features": [
            {
                "imageUrl": "/sound-machine/sounds.png",
                "headline": "Sleep Better. Live Better",
                "copy": "Over 50+ high quality sounds to help you relax and sleep better.",
            },
            {
                "imageUrl": "/sound-machine/favorites.png",
                "headline": "Favorites",
                "copy": "Save your favorite sounds.",
            },
            {
                "imageUrl": "/sound-machine/sleep-timer.png",
                "headline": "Sleep Timer & Background Audio",
                "copy": "Sounds will play in the background, and you can use the sleep timer to turn off playback.",
            },
            {
                "imageUrl": "/sound-machine/player.png",
                "headline": "No Internet Connection Required!",
                "copy": "All sounds are stored on the device. No need to always be connected online.",
            }
        ],
        "downloadLinks": [
            {
                url: 'https://apps.apple.com/us/app/sound-machine-sleep-sounds/id1468948567?ls=1',
                os: 'ios'
            }
        ]
    },
    {
        "type": AppType.MobileApp,
        "name": "Daily Verse KJV",
        "uid": "daily-verse-kjv",
        "os": ["ios"],
        "info": "Animated Verse of the Day with Today's Proverb and Psalm.",
        "landingPage": "/daily-verse-kjv",
        "appIcon": "/daily-verse-kjv/logo.png",
        "phoneImage": "/daily-verse-kjv/phone-1.png",
        "featuredBackground": "http://idevelopsolutions.com/assets/img/backgrounds/blue-white.jpg",
        "state": 'available',
        "features": [
            
        ],
        "downloadLinks": [
            {
                url: 'https://apps.apple.com/us/app/daily-verse-kjv/id635566945',
                os: 'ios'
            },
            {
                url: 'https://play.google.com/store/apps/details?id=com.cometloop.DailyVerseKJV',
                os: 'android'
            }
        ]
    },
    {
        "type": AppType.MobileApp,
        "name": "Bug Wild",
        "uid": "bug-wild",
        "os": ["ios"],
        "info": "Bugs are taking over! How many can squish before they get away?",
        "landingPage": "/tile-games-deluxe",
        "appIcon": "/apps/bug-wild/icon-320x320.png",
        "phoneImage": "/apps/bug-wild/game-1.png",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.MobileApp,
        "name": "Rocker Ship Explorer",
        "uid": "rocker-ship-explorer",
        "os": ["ios", "android"],
        "info": "Explore the Cosmos with Rocket Ship Explorer. Collect as many stars as you can while avoiding asteroids, satellites and mine fields.",
        "landingPage": "/rocket-ship-explorer",
        "appIcon": "/apps/rocket-ship-explorer/app-icon.png",
        "phoneImage": "/apps/rocket-ship-explorer/game-play1.png",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.MobileApp,
        "name": "Info Wars News",
        "uid": "info-wars-news",
        "os": ["ios"],
        "info": "There is a war on for your mind! With Info Wars News, get the latest breaking news from Info Wars right on your iOS Device.",
        "landingPage": "/info-wars-news",
        "appIcon": "/apps/infowars/app-icon.png",
        "phoneImage": "/apps/infowars/4.jpg",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.MobileApp,
        "name": "Tile Games Deluxe",
        "uid": "tile-games-deluxe",
        "os": ["ios"],
        "info": "Enjoy Tile Games? Look no further. Tile Games Deluxe has 4 exciting Tile based Games for you to Enjoy!",
        "landingPage": "/tile-games-deluxe",
        "appIcon": "/apps/tile-games-deluxe/app-icon-320x320.png",
        "phoneImage": "/apps/tile-games-deluxe/preview-1.png",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.MobileApp,
        "name": "D Report",
        "uid": "d-report",
        "os": ["ios"],
        "info": "The fastest way to read the latest headlines from Drudge Report.",
        "landingPage": "/d-report",
        "appIcon": "/apps/drudge/app-icon.png",
        "phoneImage": "/apps/drudge/1.png",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.MobileApp,
        "name": "Lucky Slots Double Payout",
        "uid": "lucky-slots",
        "os": ["ios", "android"],
        "info": "Daily Double Payouts, Superior Spin Animation, makes Lucky Slots Double Payout a Must have App for Casino lovers.",
        "landingPage": "/lucky-slots",
        "appIcon": "/apps/lucky-slots/luckyslots-icon-170x170.png",
        "phoneImage": "/apps/lucky-slots/1.png",
        "state": 'na',
        "featuredBackground": "",
        "downloadLinks": [],
        "features": [],
    },
    {
        "type": AppType.Web,
        "name": "Shuffle And Dine.com",
        "info": "Web development, Paypal integration",
        "webImage": "/websites/shuffle.jpg"
    },
    {
        "type": AppType.Web,
        "name": "St. Augustine Cast Stone.com",
        "info": "Web development and design; custom backend to manage content on website",
        "webImage": "/websites/caststone.png"
    },
    {
        "type": AppType.Web,
        "name": "Revolution Before Registration.com",
        "info": "Web development and design; Wordpress implementation",
        "webImage": "/websites/rbr.jpg"
    },
    {
        "type": AppType.Web,
        "name": "Jasper Ecotech",
        "info": "Web development and design",
        "webImage": "/websites/jasperecotech.jpg"
    },
    {
        "type": AppType.Web,
        "name": "Small.fm",
        "info": "Web development and design; custom backend; custom youtube integration",
        "webImage": "/websites/small.fm.jpg"
    },
    {
        "type": AppType.Web,
        "name": "Milestone Graphics.com",
        "info": "Web development",
        "webImage": "/websites/milestonegraphics.png"
    },
    {
        "type": AppType.Web,
        "name": "Studies in the Book.com",
        "info": "Web development and design; custom backend to manage site",
        "webImage": "/websites/studiesinthebook.png"
    },
    {
        "type": AppType.Web,
        "name": "Bible-Studies.org",
        "info": "Web development and design",
        "webImage": "/websites/bible-studies.jpg"
    },
];

export default state;