import React from 'react';

export interface IFacebookMetatagOptions {

}

export const getFacebookMetatags = (options: IFacebookMetatagOptions = {}) => {
  return [
    <meta key="og:title" property="og:title" content="Comet Loop - Apps for iOS & Android, Software Development Services" />,
    <meta key="og:description" property="og:description" content="Comet Loop - Apps for iOS & Android, Software Development Services" />,
    <meta key="og:image" property="og:image" content="https://cometloop.com/facebook-profile-pic.png" />,
    <meta key="og:url" property="og:url" content="https://cometloop.com" />
  ];
  
}