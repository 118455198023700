import initialState from './state';
import { SUPPORT_SEND_REQUEST, SUPPORT_REQUEST_ERROR, SUPPORT_REQUEST_SUCCESS, SUPPORT_CLEAR } from './actions';
import _ from 'lodash';

export default (state = initialState, action: any) => {

    let newState;

    switch(action.type) {

        case SUPPORT_CLEAR:
            newState = _.cloneDeep(state);
            newState.isSending = false;
            newState.hasError = false;
            newState.errorMessage = null;
            newState.completed = false;
            return newState;

        case SUPPORT_SEND_REQUEST:
            newState = _.cloneDeep(state);
            newState.isSending = true;
            newState.hasError = false;
            newState.errorMessage = null;
            newState.completed = false;
            return newState;

        case SUPPORT_REQUEST_ERROR:
            newState = _.cloneDeep(state);
            newState.isSending = false;
            newState.hasError = true;
            newState.errorMessage = action.error;
            newState.completed = false;
            return newState;
        
        case SUPPORT_REQUEST_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isSending = false;
            newState.hasError = false;
            newState.errorMessage = null;
            newState.completed = true;
            return newState;

        default:
            return state;
    }
}