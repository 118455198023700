import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Environment from '../../Environment';
import { Container, Row, Col } from 'react-grid-system';
import { FaInfoCircle } from 'react-icons/fa';
import { IoIosAppstore } from 'react-icons/io';
import { Line } from 'rc-progress';
import { Skill } from '../../Pages/About/skills';
import { Waypoint } from 'react-waypoint';

import _ from 'lodash';
import './Skill.scss';

interface Props {
  skill: Skill;
}

function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {

    }
  }, [delay]);
}

const SkillComponent = (props: Props) => {

  const { label, percent, description, icon, } = props.skill;

  const [x, setX] = useState(0);
  const [delay, setDelay] = useState<any>(30);
  const [time, setTime] = React.useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(() => {
    if(x > percent) {
      setIsRunning(false);
    }
    setX(x + 4);
  }, isRunning ? delay : null);


  const onEnterViewport = () => {
    //setBlueBoxInViewport(true);
    setIsRunning(true);
  }

  const onExitViewport = () => {
  }

  return (
    <Waypoint
      onEnter={onEnterViewport}
      onLeave={onExitViewport}>
      
      <div className="skill">
        {/* {icon} */}
        <p className="lbl">{label}</p>
        <p className="desc">{description}</p>
        <Line percent={x} strokeWidth={3} strokeColor="#d500f9" />
      </div>
    </Waypoint>
  )
};

export default SkillComponent;