import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { FaApple, FaAndroid, FaInfoCircle } from 'react-icons/fa';
import './DownloadButton.scss';
import { Link } from 'react-router-dom';

const DefaultIconSize = 50;

export enum DownloadButtonType {
  iOS = 'ios',
  Android = 'android',
  LearnMore = 'learn-more'
}

interface Props {
  link: string;
}

const AndroidButton = (props: Props) => {

  const { link, } = props;
  const iconSize = DefaultIconSize;

  return (
    <a className="download-button android" href={link}>
      <FaAndroid className="icon-size" size={iconSize} />
      <div className="copy">
        <span className="line-1">Get it on</span><br />
        <span className="line-2">Google Play</span>
      </div>
    </a>
  )
}

export default AndroidButton;