import { ServiceHighlightProps, ServiceHighlightImageSide } from "./ServiceHighlight";

export const items: Array<ServiceHighlightProps> = [
  {
    imageSide: ServiceHighlightImageSide.Left,
    title: 'Web',
    description: 'We build websites that are modern and standards compliant, meaning they look good on any device.',
    link: '/contact?type=free-consultation',
    className: 'web',
  },
  {
    imageSide: ServiceHighlightImageSide.Right,
    title: 'Mobile',
    description: 'We can build a mobile app for you or your business for iOS and Android.',
    link: '/contact?type=free-consultation',
    className: 'mobile',
  },
  {
    imageSide: ServiceHighlightImageSide.Left,
    title: 'Experience',
    description: 'We\'ve built apps for iOS and Android as well as numerous websites and web applications over the last 10 years.',
    link: '/contact?type=free-consultation',
    className: 'experience',
  },
  {
    imageSide: ServiceHighlightImageSide.Right,
    title: 'Quality',
    description: 'We pride ourselves on developing high quality solutions for our clients.',
    link: '/contact?type=free-consultation',
    className: 'quality',
  },
  {
    imageSide: ServiceHighlightImageSide.Left,
    title: 'Trust',
    description: 'In our combined experience, we have done work for several Fortune 500 companies, such as the NY Times, Bank of America, and Verizon.',
    link: '/contact?type=free-consultation',
    className: 'trust',
  }
];

export const homeServiceItems = [items[0], items[1]];