import _ from 'lodash';
import { App } from './state';

export const getLink = (app: App, os: string) : string|null => {
  const match = _.find(app.downloadLinks, x => x.os === os);
  return (match) ? match.url : null;
}

export const getApp = (apps: App[], uid: string): App|null => {
  const match = _.find(apps, x => x.uid === uid);
  return (match) ? match : null;
}