import React from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import App from './App';
import './index.scss';

const trackingId = 'UA-157379606-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

hydrate(
	<HelmetProvider>
		<Router history={history}>
			<App />
		</Router>
	</HelmetProvider>,
	document.getElementById('root')
);

if (module.hot) {
	module.hot.accept();
}
