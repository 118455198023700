import React from 'react';
// Pages
import HomePage from './Pages/Home/Home';
import Apps from './Pages/Apps/Apps';

import DailyVerseKJV from './Pages/DailyVerseKJV/DailyVerseKJV';

import Support from './Pages/Support/Support';
import About from './Pages/About/About';
import ContactUs from './Pages/ContactUs/ContactUs';
import Services from './Pages/Services/Services';

import MenuMobile from './Components/MenuMobile/MenuMobile';
import Footer from './Components/Footer/Footer';
import AppHeader from './Components/AppHeader/AppHeader';
import { Provider } from 'react-redux';
import store from './store';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getFacebookMetatags } from './metatags';

import { Switch, Route, Link, Redirect } from 'react-router-dom';

import './App.scss';

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<AppHeader />
			<ScrollToTop />
			<Switch>
				<Route path='/daily-verse-kjv' exact>
					<Helmet>
						<title>Comet Loop - Daily Verse KJV</title>
						<link
							rel='canonical'
							href='https://cometloop.com/daily-verse-kjv'
						/>
						<meta
							name='description'
							content="Animated Verse of the Day with Today's Proverb and Psalm. Daily Verse KJV is the free Daily Bible Verse App that you’ve been waiting for!"
						/>
						{getFacebookMetatags()};
					</Helmet>
					<DailyVerseKJV />
				</Route>
				<Route path='/support'>
					<Redirect to='/contact' />
				</Route>
				<Route path='/contact' exact>
					<Helmet>
						<title>Comet Loop - Contact</title>
						<link rel='canonical' href='https://cometloop.com/contact' />
						<meta
							name='description'
							content='Need help with your website or need a mobile app? Or have an issue with one of our apps. Contact us today and we will get back to you as soon as possible.'
						/>
						{getFacebookMetatags()};
					</Helmet>
					<Support />
				</Route>
				<Route path='/about' exact>
					<Helmet>
						<meta charSet='utf-8' />
						<title>Comet Loop - About</title>
						<link rel='canonical' href='https://cometloop.com/about' />
						<meta
							name='description'
							content="With 10+ years of experience we learned a few things a long the way.'"
						/>
						{getFacebookMetatags()};
					</Helmet>
					<About />
				</Route>
				{/* <Route path="/contact" exact>
          <Helmet>
            <title>Comet Loop - Contact</title>
            <link rel="canonical" href="https://cometloop.com/contact" />
            <meta name="description" content="Contact us if you need help building custom solutions.'" />
            {getFacebookMetatags()};
          </Helmet>
          <ContactUs />
        </Route> */}
				<Route path='/services' exact>
					<Helmet>
						<title>Comet Loop - Services</title>
						<link rel='canonical' href='https://cometloop.com/services' />
						<meta
							name='description'
							content="Web Development & Mobile App Services. Get a free estimate!'"
						/>
						{getFacebookMetatags()};
					</Helmet>
					<Services />
				</Route>
				<Route path='/' exact>
					<Helmet>
						<title>Comet Loop - Mobile Apps, Web Design and Development</title>
						<link rel='canonical' href='https://cometloop.com' />
						<meta
							name='description'
							content='Comet Loop - Apps for iOS & Android, Software Development Services'
						/>
						{...getFacebookMetatags()};
					</Helmet>
					<HomePage />
				</Route>
			</Switch>
			<Footer />
			<MenuMobile />
		</Provider>
	);
};

export default App;
