import React from 'react';
import { FaRegLightbulb, FaBug, FaCreditCard, } from 'react-icons/fa';
import { IoIosAppstore } from 'react-icons/io';
import { MdFeedback } from 'react-icons/md';
import { FiMessageCircle } from 'react-icons/fi';

export interface Reason {
  icon: any;
  label: string;
  description: string;
  id: string;
}

export const composeReasons = (iconSize: number) : Reason[] => {
  return [
  {
    id: 'free-consultation',
    icon: <FiMessageCircle className="reason-size apps"color="#00c853" />,
    label: 'Free Consultation',
    description: 'I want to schedule a time to discuss a project.'
  },
  {
    id: 'apps',
    icon: <IoIosAppstore className="reason-size apps"color="#2979ff" />,
    label: 'Apps',
    description: 'Feedback concerning one of our apps.'
  },
  {
    id: 'ideas',
    icon: <FaRegLightbulb className="reason-size ideas"color={'#ffc107'} />,
    label: 'Ideas',
    description: 'Ideas that could help us create or improve apps.'
  },
  {
    id: 'bug-report',
    icon: <FaBug className="reason-size bug"color={'#000'} />,
    label: 'Bug Report',
    description: 'Something is not working, let us know.'
  },
  {
    id: 'billing',
    icon: <FaCreditCard className="reason-size other"color={'#2962ff'} />,
    label: 'Billing',
    description: 'Subscriptions & Payments'
  },
  {
    id: 'other',
    icon: <MdFeedback className="reason-size other"color={'#673ab7'} />,
    label: 'Other',
    description: 'All other inquiries.'
  }
  ]
};