import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FaLifeRing } from 'react-icons/fa';
import _ from 'lodash';
import { items } from './items';

import './Services.scss';
import ConsultationButton from '../../Components/ConsultationButton/ConsultationButton';
import ServiceHighlight from './ServiceHighlight';
import PortfolioBlock from '../../Components/PortfolioBlock/PortfolioBlock';

const iconSize = 80;

const Services = () => {

  const serviceHighlights = _.map(items, item => {
    return <ServiceHighlight key={item.title} {...item} />
  })

  return (
    <div>

      <div className="services-top">
        <Container>
          <Row>
              <Col>
                <div className="nav-padding"></div>
                <div className="top">
                  {/* <FaLifeRing color="#fff" className="support-icon" /> */}
                  <h1>We help businesses reach <span className="magic-underline">their goals.</span></h1>
                  
                  <h2>Whether you need a website or a mobile app for iOS and Android we can help.</h2>
                  
                  <div className="cta-wrap">
                    <ConsultationButton link={'/contact?type=free-consultation'} />
                  </div>

                </div>
              </Col>
            </Row>
        </Container>
      </div>

      {serviceHighlights}

      <PortfolioBlock />

    </div>
  )
};

export default Services;