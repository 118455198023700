import React from 'react';
import './PortfolioBlock.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const fireEvent = () => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Checkout Portfolio'
  });
}

const PortfolioBlock = () => {
  return (
    <div className="portfolio-wrap">
      <Container style={{margin: 0, padding: 0}} fluid={true}>
        <Row style={{margin: 0, padding: 0}}>
          <Col style={{margin: 0, padding: 0}} xs={12}>
            <div className="bg">
              <Link onClick={fireEvent} className="btn-portfolio" to={'/portfolio'}>
                <span>Check out our Portfolio</span>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PortfolioBlock;