import _ from 'lodash';
import Environment from '../../Environment';

export enum MenuArea {
	Desktop = 'desktop',
	Mobile = 'mobile',
	Footer = 'footer',
}

export interface MenuItem {
	name: string;
	link: string;
	external?: boolean;
	targetBlank?: boolean;
	areas: MenuArea[];
}

export const menuItems: MenuItem[] = [
	{
		name: 'Home',
		link: '/',
		areas: [MenuArea.Mobile, MenuArea.Footer],
	},
	{
		name: 'Services',
		link: '/services',
		areas: [MenuArea.Desktop, MenuArea.Mobile, MenuArea.Footer],
	},
	// {
	//   name: 'Portfolio',
	//   link: '/portfolio',
	//   areas: [MenuArea.Desktop, MenuArea.Mobile, MenuArea.Footer]
	// },
	{
		name: 'About',
		link: '/about',
		areas: [MenuArea.Desktop, MenuArea.Mobile, MenuArea.Footer],
	},
	// {
	//   name: 'Support',
	//   link: '/support',
	//   areas: [MenuArea.Desktop, MenuArea.Mobile, MenuArea.Footer]
	// },
	{
		name: 'Contact',
		link: '/contact',
		areas: [MenuArea.Desktop, MenuArea.Mobile, MenuArea.Footer],
	},
	{
		name: 'Privacy Policy',
		link: Environment.PrivacyPolicy,
		external: true,
		areas: [MenuArea.Footer, MenuArea.Mobile],
	},
];

export const menu = (area: MenuArea) =>
	_.filter(menuItems, (x) => {
		const match = _.find(x.areas, (a) => {
			//console.log(a, area);
			return a === area;
		});

		return match;
	});
