import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'

// Reducers
import appsReducer from './apps/reducer';
import supportReducer from './support/reducer';
import menuReducer from './menu/reducer';

import { App } from './apps/state';
import { MenuState } from './menu/state';

export interface AppState {
    apps: App[];
    support: any;
    menu: MenuState;
}

const reducers = combineReducers({
    apps: appsReducer,
    support: supportReducer,
    menu: menuReducer,
});

const store = createStore(
    reducers, 
    {}, 
    (applyMiddleware(
        thunkMiddleware,
    ))
);

export default store;