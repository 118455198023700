import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Environment from '../../Environment';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Dropdown } from 'semantic-ui-react';

import { IoIosAppstore } from 'react-icons/io';
import { composeReasons, Reason } from './support-reasons';
import _ from 'lodash';

import './Support.scss';
import { icons } from 'react-icons/lib/cjs';
import SupportForm from '../../Components/SupportForm/SupportForm';

const iconSize = 80;

const Support = (props: any) => {

  
  const reasons = composeReasons(iconSize);
  const [selectedReason, setSelectedReason] = useState<string>('');

  useEffect(() => {
    if(typeof window !== 'undefined') {
      let query = window.location.search;
      let p = new URLSearchParams(query);
  
      if(p.has('type')) {
        const id = p.get('type') as string;
        const match = _.find(reasons, x => x.id === id);
        if(match) {
          const reasonId = match.label;
          setSelectedReason(reasonId);
        }
      }
    }
    return () => {
    
    };
  }, [])

  const reasonComponents = () => {
    

    return _.map(reasons, (x: Reason) => (
      <Col xs={12} sm={12} md={6} key={x.label}> 
        <div onClick={() => setSelectedReason(x.label)} className={'reason '+(selectedReason === x.label ? 'selected' : '')}>
          <div className="icon-wrap">{x.icon}</div>
          <div className="content">
            <p className="lbl">{x.label}</p>
            <p className="desc">{x.description}</p>
          </div>
        </div>
      </Col>
    ))

  }

  return (
    <div>
      {/* <div className="support-bg"></div> */}


      <div className="support-top">
        <Container>
          <Row>
              <Col>
                <div className="nav-padding"></div>
                <div className="topsection">
                  {/* <FaLifeRing color="#fff" className="support-icon" /> */}
                  <h1>
                    What can we help you with?
                    {/* <span className="magic-underline">their goals.</span> */}
                  </h1>
                </div>
              </Col>
            </Row>
        </Container>
      </div>




      <div className="support-wrap">
        <Container>
          <Row>
            <Col>
              <h2>Please let us know the reason you are contacting us so we can best serve you.</h2>
            </Col>
          </Row>
          <Row>
            {reasonComponents()}
          </Row>
          <Row>
            <Col>
              <div className="support-form-wrap">
                <SupportForm selectedReason={selectedReason} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

export default Support;