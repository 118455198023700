import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './MenuMobile.scss';
import { AppState } from 'store';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import _ from 'lodash';
import { MenuArea, menu, MenuItem } from '../../Components/AppHeader/nav';
import { MdClose } from 'react-icons/md';
import { openMobileMenu } from '../../store/menu/actions';

interface Props {
  show: boolean;
  close: () => void;
}

const MenuMobile = (props: Props) => {
  
  const { show, close } = props;

  const menuItems = _.map(menu(MenuArea.Mobile), (x: MenuItem) => {
    if(x.external) {
      return (
        <a key={x.link} href={x.link} target="_blank">
          {x.name}
        </a>
      )
    }

    return (
      <Link key={x.link} onClick={() => close()} to={x.link}>
        {x.name}
      </Link>
    );
  });

  const closeMenu = () => {
    // console.log('close');
    close();
  }

  return (
    <>
    {show && 
      <div className="menu-mobile">
        <div className="logo-wrap">
          <Link to="/">
            <img className="logo" src="/logo-white-transparent-200px.png" alt="Comet Loop LLC"/>
          </Link>
          <div onClick={() => closeMenu()} className="close-wrap">
            <MdClose color="#fff" size={35} />
          </div>
        </div>
        <div className="main">

          <div className="menu-wrap">
            <div className="menu-items">
              {menuItems}
            </div>
          </div>
          
        </div>
        
        <div className="bottom">
          <p className="copyright">&copy; 2019 Comet Loop LLC.</p>
        </div>
        
      </div>
    }
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  show: state.menu.mobileIsOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  close: () => {
    dispatch(openMobileMenu(false));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuMobile))