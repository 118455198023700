import React, { useState } from 'react';
import { AppState } from '../../store';
import { getApp, getLink } from '../../store/apps/selectors';
import AppItem from '../../Components/AppItem/AppItem';
import { App } from 'store/apps/state';
import { connect } from 'react-redux';

import './DailyVerseKJV.scss';

interface Props {
  app: App;
}

export const DailyVerseKJV = (props: Props) => {

  const { app } = props;

  return (
    <div className="kjv-wrap">
      <AppItem 
        key={app.name}
        colorStyle={'white'} 
        app={app} />
    </div>
  )

}

const mapStateToProps = (state: AppState) => {
  return {
    app: getApp(state.apps, 'daily-verse-kjv') as App,
  }
}

export default connect(mapStateToProps)(DailyVerseKJV);