import { initialState, MenuState } from './state';
import { OPEN_MOBILE_MENU } from './actions';
export default (state: MenuState = initialState, action: any) => {
    switch(action.type) {
        case OPEN_MOBILE_MENU:
          return {
            ...state,
            mobileIsOpen: action.open,
          };
        default:
            return state;
    }
}